import Vue from 'vue'
import DoosanOktaSignIn from '@/okta-hosted/components/DoosanOktaSignIn'
import 'svg-classlist-polyfill'
import 'whatwg-fetch'

Vue.config.productionTip = false

new Vue({
  render: h => h(DoosanOktaSignIn)
}).$mount('#wrapper')
